.root {
  padding: 8px 24px;
  background-color: #f7f7f7;
  border-radius: 8px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.listItem {
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0;
}

.day {
  font-weight: 500;
}

.hours {
  color: #4a4a4a;
}

.closed {
  color: #999;
}
