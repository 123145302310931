.customField {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
