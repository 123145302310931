@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  align-items: stretch; /* This ensures both children stretch to fill the container height */
  min-height: 250px;
  &:hover {
    text-decoration: none;
  }
}

.locationOnly {
  min-height: 100px;
}

.aspectRatioWrapper {
  flex: 0 0 auto;
  width: 30%;
  aspect-ratio: 1.5 / 1;
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  overflow: hidden; /* This ensures the image doesn't overflow */

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-top-left-radius: var(--borderRadiusMedium);
  border-bottom-left-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures the image covers the area without distortion */
}

.secondaryImage {
  position: absolute;
  height: 50%;
  width: 50%;
  right: 0;
  bottom: 0;
}

.info {
  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  border: 1px solid var(--colorGrey100);
  border-left: none;
  border-top-right-radius: var(--borderRadiusMedium);
  border-bottom-right-radius: var(--borderRadiusMedium);
}

.infoWithoutBorder {
  border: none;
  border-radius: 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.headingRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}

.title {
  /* Font */
  composes: textLarge from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
}

.authorInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  /* Font */
  font-size: 1rem;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.providerAvatar {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    margin-right: 10px;
  }
}

.ratingStars {
  margin-bottom: 16px;
}
