.root {
  display: flex;
  flex-direction: column;
  border: 1px solid #e25e38;
  border-radius: 8px;
  transition: all 0.3s ease;
  padding: 16px;
  text-decoration: none;
  color: #4a4a4a;
  margin-bottom: 24px;
}

.root:hover {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.avatar {
  margin-right: 16px;
  flex-shrink: 0;
}

.info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.name {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}

.qualifications {
  gap: 8px;
}

.qualification {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
  gap: 8px;
}

.experience {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  gap: 8px;
}

.icon {
  margin-right: 8px;
  color: #e25e38;
}

@media (max-width: 767px) {
  .root {
    padding: 12px;
    width: 80%;
    margin: 0 auto 24px 18px;
  }
}
