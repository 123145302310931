.root {
  display: flex;
  align-items: center;
}

.star {
  font-size: 18px;
  width: 18px;
  height: 18px;
  margin-right: 2px;
  color: #e0e0e0;
  position: relative;
  display: inline-block;
}

.fullStar {
  color: #e25e38;
}

.halfStar {
  color: #e0e0e0;
}

.halfStar::after {
  content: '★';
  color: #e25e38;
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  overflow: hidden;
}

.rating {
  margin-left: 4px;
  font-weight: bold;
}

.reviewCount {
  margin-left: 4px;
  color: #767676;
  font-size: 14px;
}
