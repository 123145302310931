@import '../../styles/customMediaQueries.css';

.subscriptionAlert {
  display: flex;
  flex-direction: column;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  margin-top: 16px;
  overflow: hidden;
  margin-bottom: 16px;
  gap: 8px;
  @media (max-width: 768px) {
    margin: 24px;
  }
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 24px 11px 24px;
  text-align: left;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.right {
  display: flex;
  align-items: center;
}

.missingPayoutDetailsText {
  color: var(--colorAttention);
}

.subscriptionBar {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1;
  cursor: initial;
  border-radius: var(--borderRadius);
}

.alertToggle {
  background-color: var(--colorPrimaryButton);
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  padding: 12px 16px;
  width: 100%;
}

.arrow {
  margin-left: 8px;
  transition: transform 0.3s ease;
  display: inline-block;
}

.expanded {
  transform: rotate(180deg);
}

.subscriptionOptions {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.subscriptionLink {
  background-color: #28a745;
  border-radius: 4px;
  color: white;
  display: block;
  margin-bottom: 8px;
  padding: 12px 16px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.subscriptionLink:hover {
  background-color: #218838;
}

.subscriptionLink:last-child {
  margin-bottom: 0;
}
